import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({location}) => (
  <Layout location={location}>
    <SEO title="404" />
    <div className="p-32 text-center" style={{ minHeight: "85vh" }}>
      <h1>WELL, THIS IS EMBARRASSING.</h1>
      <p>This is obviously not the page you were looking for.</p>
    </div>
  </Layout>
)

export default NotFoundPage
